<template>
  <div class="sign-in-header">
    <div class="sign-in logo">
      <a href="https://datagma.com/" target="_blank">
        <img src="@/assets/images/Datagma-logo.svg" alt="logo"
      /></a>
    </div>
    <div class="action-bar">
      <a
        href="https://datagma.com/"
        target="_blank"
        class="secondary-text weight-600"
        >Homepage</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "authHeader",
};
</script>
<style scoped>
.logo img {
  width: 150px;
}
.sign-in-header {
  display: flex;
  justify-content: space-between;
  padding: 15.5px 32px;
}

@media only screen and (max-width: 576px) {
  .sign-in-header {
    padding: 15.5px 15px;
  }
}
</style>